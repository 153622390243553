@use "sass:list";
@use "./variables/grid-variables" as gridVariables;
.show {
  display: block !important;
}

.cat_row.show {
  display: -webkit-flex !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.hide {
  display: none !important;
}

// * Not for developer use *
@mixin vis-factory($thisPrefix) {
  .show-#{$thisPrefix} {
    display: block !important;
  }
  .cat_row.show-#{$thisPrefix} {
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .hide-#{$thisPrefix} {
    display: none !important;
  }
} // * Not for developer use *

@for $i from 1 through list.length(gridVariables.$breakpoint-all-prefixes) {
  $thisPrefix: list.nth(gridVariables.$breakpoint-all-prefixes, $i);
  @if $thisPrefix == "xs" {
    @include vis-factory($thisPrefix);
  } @else if $thisPrefix == "xs-only" {
    @media #{gridVariables.$breakpoint-xs-only} {
      @include vis-factory($thisPrefix);
    }
  } @else if $thisPrefix == "sm" {
    @media #{gridVariables.$breakpoint-sm-up} {
      @include vis-factory($thisPrefix);
    }
  } @else if $thisPrefix == "sm-only" {
    @media #{gridVariables.$breakpoint-sm-only} {
      @include vis-factory($thisPrefix);
    }
  } @else if $thisPrefix == "md" {
    @media #{gridVariables.$breakpoint-md-up} {
      @include vis-factory($thisPrefix);
    }
  } @else if $thisPrefix == "md-only" {
    @media #{gridVariables.$breakpoint-md-only} {
      @include vis-factory($thisPrefix);
    }
  } @else if $thisPrefix == "lg" {
    @media #{gridVariables.$breakpoint-lg-up} {
      @include vis-factory($thisPrefix);
    }
  } @else if $thisPrefix == "lg-only" {
    @media #{gridVariables.$breakpoint-lg-only} {
      @include vis-factory($thisPrefix);
    }
  } @else if $thisPrefix == "xl" {
    @media #{gridVariables.$breakpoint-xl-up} {
      @include vis-factory($thisPrefix);
    }
  }
}
