// to https://github.com/dev-abuhasan
// No gutters
// No text alignment with justified container classes (center-xs, for example)
@use "./mixins/grid-mixins" as gridMixins;
@use "./variables/grid-variables" as gridVariables;
@use "sass:math";
@use "sass:list";

.cat_row {
  @include gridMixins.row();
}

.cat_row.reverse {
  @include gridMixins.row-reverse();
}

.cat_row.natural-height {
  @include gridMixins.row-natural-height();
}

.cat_row.flex-column {
  @include gridMixins.row-flex-column();
}

.col {
  @include gridMixins.col();
}

.col.reverse {
  @include gridMixins.col-reverse();
}

.first {
  order: -1;
}

.last {
  order: 1;
}

.align-start {
  align-self: flex-start;
}

.align-end {
  align-self: flex-end;
}

.align-center {
  align-self: center;
}

.align-baseline {
  align-self: baseline;
}

.align-stretch {
  align-self: stretch;
}

.cat_row {
  // Mixin to run inside of for loop - creates col/breakpoint classes * Not for developer use *
  @mixin col-factory($thisPrefix) {
    .col-#{$thisPrefix} {
      box-sizing: border-box;
      flex-grow: 1;
      flex-basis: 0;
      max-width: 100%;
      padding: gridVariables.$gutterCart;
    }
    @for $i from 1 through gridVariables.$grid-columns {
      .col-#{$thisPrefix}-#{$i} {
        box-sizing: border-box;
        flex-basis: math.div(100%, gridVariables.$grid-columns) * $i;
        max-width: math.div(100%, gridVariables.$grid-columns) * $i;
        padding: gridVariables.$gutterCart;
      }
      .col-#{$thisPrefix}-offset-#{$i} {
        margin-left: math.div(100%, gridVariables.$grid-columns) * $i;
      }
    }
    .cat_row.start-#{$thisPrefix} {
      justify-content: flex-start;
    }
    .cat_row.center-#{$thisPrefix} {
      justify-content: center;
    }
    .cat_row.end-#{$thisPrefix} {
      justify-content: flex-end;
    }
    .cat_row.top-#{$thisPrefix} {
      align-items: flex-start;
    }
    .cat_row.middle-#{$thisPrefix} {
      align-items: center;
    }
    .cat_row.bottom-#{$thisPrefix} {
      align-items: flex-end;
    }
    .cat_row.around-#{$thisPrefix} {
      justify-content: space-around;
    }
    .cat_row.between-#{$thisPrefix} {
      justify-content: space-between;
    }
    .first-#{$thisPrefix} {
      order: -1;
    }
    .last-#{$thisPrefix} {
      order: 1;
    }
  } // Mixin to run inside of for loop - creates col/breakpoint classes

  @for $i from 1 through list.length(gridVariables.$breakpoint-up-prefixes) {
    $thisPrefix: list.nth(gridVariables.$breakpoint-up-prefixes, $i);
    @if $thisPrefix == "xs" {
      @include col-factory($thisPrefix);
    } @else if $thisPrefix == "sm" {
      @media #{gridVariables.$breakpoint-sm-up} {
        @include col-factory($thisPrefix);
      }
    } @else if $thisPrefix == "md" {
      @media #{gridVariables.$breakpoint-md-up} {
        @include col-factory($thisPrefix);
      }
    } @else if $thisPrefix == "lg" {
      @media #{gridVariables.$breakpoint-lg-up} {
        @include col-factory($thisPrefix);
      }
    } @else if $thisPrefix == "xl" {
      @media #{gridVariables.$breakpoint-xl-up} {
        @include col-factory($thisPrefix);
      }
    }
  }

  .col-gutter-lr {
    padding: 0 gridVariables.$gutterCart;
  }

  .col-no-gutter {
    padding: 0;
  }
}
