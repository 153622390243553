// to https://github.com/dev-abuhasan
// No gutters
// No text alignment with justified container classes (center-xs, for example)
@use "sass:math";
@use "sass:list";
@use "./mixins/grid-mixins" as gridMixins;
@use "./variables/grid-variables" as gridVariables;

.row {
  @include gridMixins.row();
}

.row.reverse {
  @include gridMixins.row-reverse();
}

.row.natural-height {
  @include gridMixins.row-natural-height();
}

.row.flex-column {
  @include gridMixins.row-flex-column();
}

.col {
  @include gridMixins.col();
}

.col.reverse {
  @include gridMixins.col-reverse();
}

.first {
  order: -1;
}

.last {
  order: 1;
}

.align-start {
  align-self: flex-start;
}

.align-end {
  align-self: flex-end;
}

.align-center {
  align-self: center;
}

.align-baseline {
  align-self: baseline;
}

.align-stretch {
  align-self: stretch;
}

// Mixin to run inside of for loop - creates col/breakpoint classes * Not for developer use *
@mixin col-factory($thisPrefix) {
  .col-#{$thisPrefix} {
    box-sizing: border-box;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    padding: gridVariables.$gutter;
  }
  @for $i from 1 through gridVariables.$grid-columns {
    .col-#{$thisPrefix}-#{$i} {
      box-sizing: border-box;
      flex-basis: math.div(100%, gridVariables.$grid-columns) * $i;
      max-width: math.div(100%, gridVariables.$grid-columns) * $i;
      padding: gridVariables.$gutter;
    }
    .col-#{$thisPrefix}-offset-#{$i} {
      margin-left: math.div(100%, gridVariables.$grid-columns) * $i;
    }
  }
  .row.start-#{$thisPrefix} {
    justify-content: flex-start;
  }
  .row.center-#{$thisPrefix} {
    justify-content: center;
  }
  .row.end-#{$thisPrefix} {
    justify-content: flex-end;
  }
  .row.top-#{$thisPrefix} {
    align-items: flex-start;
  }
  .row.middle-#{$thisPrefix} {
    align-items: center;
  }
  .row.bottom-#{$thisPrefix} {
    align-items: flex-end;
  }
  .row.around-#{$thisPrefix} {
    justify-content: space-around;
  }
  .row.between-#{$thisPrefix} {
    justify-content: space-between;
  }
  .first-#{$thisPrefix} {
    order: -1;
  }
  .last-#{$thisPrefix} {
    order: 1;
  }
} // Mixin to run inside of for loop - creates col/breakpoint classes

@for $i from 1 through list.length(gridVariables.$breakpoint-up-prefixes) {
  $thisPrefix: list.nth(gridVariables.$breakpoint-up-prefixes, $i);
  @if $thisPrefix == "xs" {
    @include col-factory($thisPrefix);
  } @else if $thisPrefix == "sm" {
    @media #{gridVariables.$breakpoint-sm-up} {
      @include col-factory($thisPrefix);
    }
  } @else if $thisPrefix == "md" {
    @media #{gridVariables.$breakpoint-md-up} {
      @include col-factory($thisPrefix);
    }
  } @else if $thisPrefix == "lg" {
    @media #{gridVariables.$breakpoint-lg-up} {
      @include col-factory($thisPrefix);
    }
  } @else if $thisPrefix == "xl" {
    @media #{gridVariables.$breakpoint-xl-up} {
      @include col-factory($thisPrefix);
    }
  }
}

.col-gutter-lr {
  padding: 0 gridVariables.$gutter;
}

.col-no-gutter {
  padding: 0;
}
